<template>
	<div>
		<v-row>
			<v-col md="4" class="my-auto py-0">
				<label :for="`expected-start-date-${uniqFieldId}`" class="btx-label mt-3">Recuring Type</label>
			</v-col>
			<v-col md="8" class="py-0">
				<AutoCompleteInput
					hide-details
					:items="recuringTypeList"
					item-text="label"
					item-value="value"
					:disabled="pageLoading"
					:loading="pageLoading"
					:id="`service-type-${uniqFieldId}`"
					placeholder="Service Category"
					v-model="pattern.type"
				></AutoCompleteInput>
			</v-col>
			<template v-if="pattern.type == 'daily'">
				<v-col md="4" class="my-auto py-0">
					<label :for="`expected-start-date-${uniqFieldId}`" class="btx-label mt-3"></label>
				</v-col>
				<v-col md="8" class="py-0">
					<v-radio-group
						mandatory
						row
						hide-details
						class="mt-3 pt-0"
						v-model.trim="pattern.daily.recurring_pattern"
						:disabled="loading || pattern.type != 'daily'"
					>
						<v-radio value="2">
							<template v-slot:label>
								<table>
									<tr>
										<td>Every</td>
										<td>
											<TextInput
												v-mask="'###'"
												hide-details
												:rules="
													pattern.daily.recurring_pattern != 2
														? []
														: [vrules.required(pattern.daily.day_count, 'Day')]
												"
												:loading="loading"
												:disabled="loading || pattern.daily.recurring_pattern != 2"
												v-model.trim="pattern.daily.day_count"
												class="mx-2 min-width-100px max-width-100px"
											></TextInput>
										</td>
										<td>day(s)</td>
									</tr>
								</table>
							</template>
						</v-radio>
						<v-radio label="Weekday" class="ml-4" value="1"></v-radio>
					</v-radio-group>
				</v-col>
			</template>
			<template v-else-if="pattern.type == 'weekly'">
				<v-col md="4" class="my-auto py-0">
					<label :for="`expected-start-date-${uniqFieldId}`" class="btx-label mt-3"></label>
				</v-col>
				<v-col md="8" class="py-0">
					<v-radio-group
						mandatory
						class="mt-0"
						v-model.trim="pattern.weekly.recurring_pattern"
						hide-details
						:disabled="loading || pattern.type != 'weekly'"
					>
						<v-radio value="1">
							<template v-slot:label>
								<table>
									<tr>
										<td>Every</td>
										<td>
											<TextInput
												v-mask="'###'"
												hide-details
												:rules="[vrules.required(pattern.weekly.week_count, 'Count')]"
												:loading="loading"
												:disabled="loading"
												v-model.trim="pattern.weekly.week_count"
												class="mx-2 min-width-100px max-width-100px"
											></TextInput>
										</td>
										<td>week(s) on</td>
									</tr>
								</table>
							</template>
						</v-radio>
					</v-radio-group>
					<v-row>
						<v-col v-for="(weekday, index) in weekDays" :key="index">
							<v-checkbox
								:rules="[vrules.required(pattern.weekly.weeks, 'Weeks')]"
								v-model.trim="pattern.weekly.weeks"
								:label="showCalendar ? weekday.short_name : weekday.long_name"
								:value="weekday.id"
								hide-details
								class="mt-0"
								:disabled="loading || pattern.type != 'weekly'"
							></v-checkbox>
						</v-col>
					</v-row>
				</v-col>
			</template>
			<template v-else-if="pattern.type == 'monthly'">
				<v-col md="4" class="my-auto py-0">
					<label :for="`expected-start-date-${uniqFieldId}`" class="btx-label mt-3"></label>
				</v-col>
				<v-col md="8" class="py-0">
					<v-radio-group
						mandatory
						class="mt-0"
						v-model.trim="pattern.monthly.recurring_pattern"
						hide-details
						:disabled="loading || pattern.type != 'monthly'"
					>
						<v-radio value="1" class="align-items-start radio-icon-start">
							<template v-slot:label>
								<div class="d-flex flex-wrap align-center">
									<span>Day</span>
									<span class="mb-1">
										<TextInput
											v-mask="'###'"
											hide-details
											:rules="
												pattern.monthly.recurring_pattern != 1
													? []
													: [vrules.required(pattern.monthly.day_count, 'Day')]
											"
											:loading="loading"
											:disabled="loading || pattern.monthly.recurring_pattern != 1"
											v-model.trim="pattern.monthly.day_count"
											class="mx-2 min-width-100px max-width-100px"
										></TextInput>
									</span>
									<span>of every</span>
									<span class="mb-1">
										<TextInput
											v-mask="'###'"
											hide-details
											:rules="
												pattern.monthly.recurring_pattern != 1
													? []
													: [vrules.required(pattern.monthly.day_month_count, 'Month')]
											"
											:loading="loading"
											:disabled="loading || pattern.monthly.recurring_pattern != 1"
											v-model.trim="pattern.monthly.day_month_count"
											class="mx-2 min-width-100px max-width-100px"
										></TextInput>
									</span>
									<span>month(s)</span>
								</div>
							</template>
						</v-radio>
						<v-radio value="2" class="align-items-start radio-icon-start">
							<template v-slot:label>
								<div class="d-flex flex-wrap align-center">
									<span>The</span>
									<span class="mb-1">
										<v-select
											:items="jobDayOfMonths"
											dense
											filled
											flat
											item-text="value"
											item-value="id"
											label="Day of Month"
											hide-details
											solo
											:rules="
												pattern.monthly.recurring_pattern != 2
													? []
													: [vrules.required(pattern.monthly.week_day, 'Week')]
											"
											:loading="loading"
											:disabled="loading || pattern.monthly.recurring_pattern != 2"
											v-model.trim="pattern.monthly.week_day"
											class="mx-2 width-150px"
										>
										</v-select>
									</span>
									<span class="mb-1">
										<v-select
											:items="weekDays"
											dense
											filled
											flat
											item-text="long_name"
											item-value="id"
											label="Weekday"
											hide-details
											solo
											:rules="
												pattern.monthly.recurring_pattern != 2
													? []
													: [vrules.required(pattern.monthly.week_count, 'Count')]
											"
											:loading="loading"
											:disabled="loading || pattern.monthly.recurring_pattern != 2"
											v-model.trim="pattern.monthly.week_count"
											class="mx-2 width-150px"
										>
										</v-select>
									</span>
									<span>of every</span>
									<span class="mb-1">
										<TextInput
											hide-details
											placeholder="File Name"
											v-mask="'###'"
											:rules="
												pattern.monthly.recurring_pattern != 2
													? []
													: [vrules.required(pattern.monthly.month_count, 'Month')]
											"
											:loading="loading"
											:disabled="loading || pattern.monthly.recurring_pattern != 2"
											v-model.trim="pattern.monthly.month_count"
											class="mx-2 width-100px"
										></TextInput>
									</span>
									<span>month(s)</span>
								</div>
								<!-- </perfect-scrollbar> -->
							</template>
						</v-radio>
					</v-radio-group>
				</v-col>
			</template>
		</v-row>
	</div>
</template>

<script>
import { cloneDeep } from "lodash";
import TextInput from "@/view/components/TextInput";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
export default {
	name: "recurring-pattern-template",
	props: {
		showCalendar: {
			type: Boolean,
			required: true,
		},
		loading: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			timeout: null,
			recuringTypeList: [
				{
					label: "Daily",
					value: "daily",
				},
				{
					label: "Weekly",
					value: "weekly",
				},
				{
					label: "Monthly",
					value: "monthly",
				},
			],
			weekDays: [
				{ id: 1, short_name: "Sun", long_name: "Sunday" },
				{ id: 2, short_name: "Mon", long_name: "Monday" },
				{ id: 3, short_name: "Tue", long_name: "Tuesday" },
				{ id: 4, short_name: "Wed", long_name: "Wednesday" },
				{ id: 5, short_name: "Thu", long_name: "Thursday" },
				{ id: 6, short_name: "Fri", long_name: "Friday" },
				{ id: 7, short_name: "Sat", long_name: "Saturday" },
			],
			timeoutLimit: 500,
			pattern: {
				type: "daily",
				daily: {
					day_count: "1",
					recurring_pattern: "2",
				},
				weekly: {
					recurring_pattern: "1",
					week_count: "1",
					weeks: [1],
				},
				monthly: {
					recurring_pattern: "1",
					day_count: "1",
					week_day: 1,
					week_count: 1,
					day_month_count: "1",
					month_count: "1",
				},
			},
		};
	},
	components: {
		TextInput,
		AutoCompleteInput,
	},
	watch: {
		"pattern.type"(param) {
			const _this = this;
			if (param.toString() === "daily") {
				_this.pattern.daily.day_count = "1";
				_this.pattern.daily.recurring_pattern = "2";
			}
			if (param.toString() === "weekly") {
				_this.pattern.weekly.week_count = "1";
				_this.pattern.weekly.weeks = [1];
				_this.pattern.weekly.recurring_pattern = "2";
			}
			if (param.toString() === "monthly") {
				_this.pattern.monthly.recurring_pattern = "1";
				_this.pattern.monthly.day_count = "1";
				_this.pattern.monthly.week_day = 1;
				_this.pattern.monthly.week_count = 1;
				_this.pattern.monthly.day_month_count = "1";
				_this.pattern.monthly.month_count = "1";
			}
		},
		pattern: {
			deep: true,
			immediate: true,
			handler(param) {
				const pattern = cloneDeep(param);

				if (pattern.type == "daily") {
					if (pattern.daily.recurring_pattern == 1) {
						pattern.daily.day_count = 0;
					}
				}

				this.$nextTick(() => {
					this.emitEvent();
				});
			},
		},
	},
	methods: {},
	computed: {
		verticalScrollbar() {
			return "max-width: " + (this.showCalendar ? "60vh;" : "100%;");
		},
	},
};
</script>
