<template>
	<div>
		<v-row>
			<v-col md="4" class="my-auto py-0">
				<label :for="`expected-start-date-${uniqFieldId}`" class="btx-label mt-3">Start Date</label>
			</v-col>
			<v-col md="8" class="py-0">
				<DateTimePicker
					hide-details
					:min-date="today"
					:disabled="pageLoading"
					:loading="pageLoading"
					:id="`expected-start-date-${uniqFieldId}`"
					placeholder="Expected Start Date"
					v-model="schedule.start_date"
					v-on:change="updateDates()"
				></DateTimePicker>
			</v-col>
			<v-col md="12" class="py-0">
				<RecurringPatternTemplate
					:loading="recurringLoading"
					:showCalendar="showCalendar"
				></RecurringPatternTemplate>
			</v-col>
			<v-col md="4" class="my-auto py-0">
				<label :for="`end-recurrence-date-${uniqFieldId}`" class="btx-label mt-3"
					>End of Recurrence</label
				>
			</v-col>
			<v-col md="8" class="py-0">
				<v-container class="px-0">
					<v-radio-group
						class="mt-0"
						hide-details
						mandatory
						row
						:disabled="recurringLoading"
						v-model.trim="schedule.end_mode"
					>
						<v-radio v-if="false" :value="1" hide-details>
							<template v-slot:label>
								<table>
									<tr>
										<td>No end date</td>
									</tr>
								</table>
							</template>
						</v-radio>
						<v-radio :value="2" hide-details>
							<template v-slot:label>
								<table>
									<tr>
										<td>End after</td>
										<td>
											<TextInput
												v-mask="'###'"
												hide-details
												:rules="
													schedule.end_mode != 2 ? [] : [vrules.required(schedule.occurrence, 'Occurrence')]
												"
												v-model.trim="schedule.occurrence"
												:loading="recurringLoading"
												:disabled="recurringLoading || schedule.end_mode != 2"
												class="mx-2 width-100px"
											></TextInput>
										</td>
										<td>occurrences</td>
									</tr>
								</table>
							</template>
						</v-radio>
						<v-radio label="" :value="3" hide-details class="ml-4">
							<template v-slot:label>
								<table>
									<tr>
										<td width="80">End after</td>
										<td>
											<DatePicker
												placeholder="End Date"
												id="export-from-date"
												v-model="schedule.end_date"
												:rules="[vrules.required(schedule.end_date, 'End Date')]"
												:class="{
													required: !schedule.end_date,
												}"
											></DatePicker>
										</td>
									</tr>
								</table>
							</template>
						</v-radio>
					</v-radio-group>
				</v-container>
			</v-col>
			<v-col md="4" class="pb-0"></v-col>
			<v-col md="8" class="pb-0">
				<v-layout>
					<v-flex class="ml-2">
						<v-btn
							color="blue white--text"
							v-on:click="dateDialog = true"
							class="custom-grey-border custom-bold-button h-100 mt-2"
							>Show Schedule</v-btn
						>
					</v-flex>
				</v-layout>
			</v-col>
		</v-row>
		<Dialog :dialog="dateDialog" :dialog-width="dialogWidth">
			<template v-slot:title
				>Service Schedules ({{ visit_schedule.length }}) {{ getDuration() }}
			</template>
			<template v-slot:body>
				<v-container>
					<table width="100%">
						<tr
							v-for="(row, index) in visit_schedule"
							:key="index"
							:class="{ 'visit-row-hover': row.is_edited }"
						>
							<td class="font-size-14 font-weight-500" align="center" valign="middle">{{ index + 1 }}.</td>
							<td>
								<Datepicker
									solo
									flat
									:disabled="!row.status"
									:default-date="row.visit_date"
									v-model="row.visit_date"
									v-on:change="row.is_edited = 1"
									mandatory
								></Datepicker>
							</td>
							<td>
								<v-text-field
									readonly
									solo
									flat
									dense
									:disabled="!row.status"
									filled
									hide-details
									:value="getDayName(row.visit_date)"
								></v-text-field>
							</td>
							<td>
								<Timepicker
									start-time-picker
									:disabled="!row.status"
									:default-time="row.start_time"
									v-model="row.start_time"
									v-on:change="row.is_edited = 1"
									placeholder="Start Time"
								></Timepicker>
							</td>
							<td>
								<Timepicker
									end-time-picker
									:disabled="!row.status"
									:default-time="row.end_time"
									v-model="row.end_time"
									v-on:change="row.is_edited = 1"
									placeholder="End Time"
								></Timepicker>
							</td>
							<td align="center" valign="middle">
								<v-switch
									class="m-0 p-0"
									color="cyan"
									dense
									hide-details
									:false-value="0"
									:true-value="1"
									v-on:change="row.is_edited = 1"
									v-model="row.status"
									inset
								></v-switch>
							</td>
						</tr>
					</table>
				</v-container>
			</template>
			<template v-slot:action>
				<v-btn
					class="mx-2 custom-grey-border custom-bold-button white--text"
					color="cyan"
					v-on:click="validateSchedule()"
				>
					Save
				</v-btn>
				<v-btn class="mx-2 custom-grey-border custom-bold-button" v-on:click="dateDialog = false">
					Close
				</v-btn>
			</template>
		</Dialog>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import { toSafeInteger, cloneDeep, findIndex, map } from "lodash";
import ApiService from "@/core/services/api.service";
import { UploadFiles } from "@/core/lib/upload.lib";
import { SET_ERROR } from "@/core/services/store/common.module";
import { EventBus } from "@/core/event-bus/event.bus";
import moment from "moment-timezone";
import ObjectPath from "object-path";
import RecurringPatternTemplate from "@/view/components/Recurring-Pattern-Template";
import DateTimePicker from "@/view/components/DateTimePicker";
import TextInput from "@/view/components/TextInput";
import DatePicker from "@/view/components/DatePicker";
import Dialog from "@/view/components/Dialog";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
	name: "service-template",
	title: "Service Template",
	props: {
		type: {
			type: String,
			default: null,
		},
		typeUuid: {
			type: String,
			default: null,
		},
		endpoint: {
			type: String,
			default: null,
		},
		serviceDialog: {
			type: Boolean,
			default: false,
		},
		serviceArray: {
			type: Object,
			default: null,
		},
	},
	watch: {
		serviceDialog(param) {
			if (param) {
				this.init();
			}
		},
		serviceArray(param) {
			if (param) {
				this.service = {
					type: param.type,
					from: param.actual_start_date,
					to: param.actual_end_date,
					cost: param.cost,
					expected_completion: param.expected_end_date,
					inventories: [],
					attachments: [],
					service_type: param.service_type,
					performed_by: param.service_by,
					location_by: param.location_by,
					indefinitely: param.indefinitely,
					vendor: param.supplier,
					member: param.member,
					description: param.description,
				};
				this.serviceUuid = param.uuid;
			}
		},
	},
	data() {
		return {
			formValid: true,
			pageLoading: false,
			checkAll: false,
			service_setting: {},
			subparts: [],
			endDatePicker: false,
			asset_setting: {},
			serviceTypeList: [],
			vendorList: [],
			memberList: [],
			inventoryList: [],
			manageServiceTypeDialog: false,
			inventory: {},
			serviceUuid: null,
			recurringLoading: false,
			schedule: {
				end_mode: 2,
				start_date: null,
				end_date: null,
				start_time: null,
				end_time: null,
				occurrence: 1,
				schedule_later: 0,
			},

			serviceCategories: [
				{
					label: "Item Unavailable",
					value: 1,
					description: "Make Item unavailable (Item will be put in maintenance state)",
				},
				{
					label: "Create Record",
					value: 2,
					description: "Create a service record. Do not make item unavailable",
				},
			],
			serviceType: [
				{
					label: "One-off Service",
					value: 1,
					description: "A one time service",
				},
				{
					label: "Recurring Service",
					value: 2,
					description: "A contract service with repeating services",
				},
			],
			performedItems: [
				{
					label: "Supplier",
					value: 1,
				},
				{
					label: "Staff",
					value: 2,
				},
			],
			performedPlaceItems: [
				{
					label: "Onsite",
					value: 1,
				},
				{
					label: "Workshop",
					value: 2,
				},
			],
		};
	},
	components: {
		RecurringPatternTemplate,
		DateTimePicker,
		TextInput,
		Dialog,
		DatePicker,
	},
	methods: {
		manageAllSubpartCheck(val) {
			if (val) {
				this.service.subparts = map(this.subparts, (row) => row.id);
			} else {
				this.service.subparts = [];
			}
		},
		validateAllCheck() {
			if (
				this.service.subparts &&
				this.subparts &&
				this.service.subparts.length == this.subparts.length
			) {
				this.checkAll = true;
			} else {
				this.checkAll = false;
			}
		},
		addMore() {
			this.service.attachment.push({
				file: null,
				url: null,
				name: null,
			});
		},
		removeFile(index) {
			this.service.attachment.splice(index, 1);
			if (this.service.attachment.length < 1) {
				this.addMore();
			}
		},
		limitAttachment(output, index) {
			if (this.pageLoading) {
				return false;
			}

			const attachment = output.slice(0, 3);

			if (attachment.length <= 0) {
				return false;
			}

			const request = new FormData();

			for (let i = 0; i < attachment.length; i++) {
				request.append(`files[${i}]`, attachment[i]);
			}

			this.pageLoading = true;

			UploadFiles(request)
				.then((response) => {
					this.service.attachment[index].url = ObjectPath.get(response, "0.path", null);
					this.service.attachment[index].name = ObjectPath.get(response, "0.name", null);
				})
				.catch((error) => {
					this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		init() {
			this.service = new Object({
				type: 1,
				from: null,
				to: null,
				cost: null,
				expected_completion: null,
				inventories: [],
				attachment: [
					{
						file: null,
						url: null,
						name: null,
					},
				],
				service_type: null,
				performed_by: 1,
				location_by: 1,
				indefinitely: 0,
				vendor: 0,
				member: 0,
				description: null,
			});
		},
		selectInventory() {
			const inventory = cloneDeep(this.inventory);
			this.inventory = new Object({});
			let preInventory = findIndex(this.service.inventories, { id: inventory.value });
			if (preInventory != -1) {
				this.service.inventories[preInventory].quantity =
					toSafeInteger(this.service.inventories[preInventory].quantity) + 1;
			} else {
				this.service.inventories.push({
					id: inventory.value,
					uuid: inventory.uuid,
					name: inventory.text,
					quantity: 1,
				});
			}
		},
		onSubmit() {
			const _this = this;

			const formErrors = _this.validateForm(_this.$refs.serviceForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.serviceForm.validate()) {
				return false;
			}

			this.pageLoading = true;

			ApiService.patch(`${this.endpoint}/${this.typeUuid}/service-start`, this.service)
				.then(() => {
					this.$emit("success");
					EventBus.$emit("reload:asset-service", true);
				})
				.catch((error) => {
					this.$emit("error", error);
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		onUpdateSubmit() {
			const _this = this;

			const formErrors = _this.validateForm(_this.$refs.serviceForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.serviceForm.validate()) {
				return false;
			}

			this.pageLoading = true;

			ApiService.put(`${this.endpoint}/service-start/${this.serviceUuid}`, this.service)
				.then(() => {
					this.$emit("success");
					EventBus.$emit("reload:asset-service", true);
				})
				.catch((error) => {
					this.$emit("error", error);
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		removeInventory(index) {
			let inventoryArr = this.service.inventories;
			inventoryArr.splice(index, 1);
			this.service.inventories = inventoryArr;
		},
		getServiceTypes(data) {
			this.serviceTypeList = data;
		},
	},
	mounted() {
		this.serviceTypeList = this.localDB("service_types", []);
		this.vendorList = this.localDB("suppliers", []);
		this.memberList = this.localDB("members", []);
		this.inventoryList = this.localDB("inventories", []);
		this.asset_setting = this.localDB("asset_setting", {});
		this.service_setting = this.localDB("service_setting", {});
	},
	computed: {
		...mapGetters(["errors", "localDB"]),
		dialogWidth() {
			return toSafeInteger((document.body.clientWidth / 100) * 50);
		},
		todayDate() {
			return moment().format("YYYY-MM-DD");
		},
		formattedStartDate() {
			if (this.schedule.end_date && moment(this.schedule.start_date).isAfter(this.schedule.end_date)) {
				return null;
			}
			return moment(this.schedule.start_date).format("DD-MM-YYYY");
		},
		formattedEndDate() {
			if (
				this.schedule.start_date &&
				moment(this.schedule.end_date).isBefore(this.schedule.start_date)
			) {
				return null;
			}
			return moment(this.schedule.end_date).format("DD-MM-YYYY");
		},
	},
};
</script>
